import React, { createContext, useContext, useState, useEffect } from "react";
import {
  initXurealAI,
  createChatBot,
  getUserChatbots,
  onboardUser,
  initJWT,
  getUserData,
  createSources,
  createFileUploadSource,
  postMediaContent,
} from "../actions";
import { AuthContext, ChatbotType, MessageType, CurrentUser, UploadStatusType, SourceType } from "../types/AuthTypes";
import io from "socket.io-client";

declare var infoboxaiBETA: any;

const ClientCtx = createContext({});
const socket = io(process.env.REACT_APP_SCRAPER_SOCKET as string);

const ClientProvider = ({ children }: { children: React.ReactNode }) => {
  const [authorized, setAuthorized] = useState(false);
  const [initialized, setInitialize] = useState(false);
  const [currentUser, setCurrentUser] = useState<CurrentUser>({
    email: "",
    onboarded: false,
    firstName: "",
    lastName: "",
    userID: "",
    status: 1,
  });
  const [isBotReady, setBotReady] = useState(false);
  const [isForgotOpen, setForgotOpen] = useState(false);
  const [creationModalOpen, setCreateOpen] = useState(false);
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [assistantName] = useState("");
  const [companyName, setCompanyName] = useState("Elevux");
  const [isLoginOpen, setLoginOpen] = useState(false);
  const [isSignupOpen, setSignupOpen] = useState(false);
  const [isFeedbackOpen, setFeedbackOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const [selectedSources, setSelectedSources] = useState<
    {
      name: string;
      sourceType: string;
      sourceData: { [key: string]: any };
    }[]
  >([]);
  const [jsonWebToken, setJsonWebToken] = useState(localStorage.getItem("JWT_TOKEN") || "");
  const [googleDriveConnected, setGoogleDriveConnected] = useState<boolean | undefined>();
  const [isDemo] = useState(false);
  const [progressData,] = useState({
    currentSource: "",
    progress: 0,
  });
  const [uploadStatus, setUploadStatus] = useState<{
    sourceID: string;
    upload_status: UploadStatusType;
  }>({
    sourceID: "",
    upload_status: {
      type: "queued",
      message: "",
    },
  });
  const [logoData] = useState({
    logo: "",
    logoColor: "",
  });
  const [data, setData] = useState<{ header: string; section: string }[]>([]);

  const [loading, setLoading] = useState(false);
  const [currentChatbot, setCurrentChatbot] = useState<ChatbotType | undefined>();

  const [userChatbots, setUserChatbots] = useState<ChatbotType[]>([]);
  const [sources, setSources] = useState<SourceType[]>([]);

  const initializeApp = async () => {
    let initAI = initXurealAI();
    if (!initAI) {
      console.log("API is unable to connect");
      return;
    }
    console.log("setting socket");

    if (localStorage.getItem("JWT_TOKEN")) {
      initJWT(localStorage.getItem("JWT_TOKEN") as string).then(() => {
        setAuthorized(true);
        getUserData().then((res: any) => {
          const isOnboarded = res.content.onboarded;
          setCurrentUser(res.content as CurrentUser);
          // navigate(isOnboarded ? "/data" : "/create", { replace: true });
        });
      });
    }

    Promise.all([initAI])
      .then((res) => {
        if (res[0] === "SUCCESS") {
          console.log("%c Infobox AI initialized", "font-size:16px;color:aquamarine");
          setInitialize(true);
        }
        if (res[0] === "FAILED") {
          // window.location.href = process.env.REACT_APP_REDIRECT as string;
        }
      })
      .catch(() => {
        // window.location.href = process.env.REACT_APP_REDIRECT as string;
      });
  };

  useEffect(() => {
    if (localStorage.getItem("JWT_TOKEN") && localStorage.getItem("REFRESH_TOKEN")) {
      let token = localStorage.getItem("JWT_TOKEN") as string;
      initJWT(token)
        .then(() => {
          setAuthorized(true);
          getUserData().then((res: { result: string; content: CurrentUser }) => {
            console.log(res);
            setCurrentUser(res.content as CurrentUser);
          });
        })
        .catch((error: any) => {
          console.log("verify encoding error", error);
        });
      return;
    }
  }, []);

  //check user status
  useEffect(() => {
    if (currentUser && currentUser.status === 3) {
      window.location.href = "/logout";
    }
  }, [currentUser]);

  // intialize the application and get the configuration data
  useEffect(() => {
    console.log("initialized", initialized);
    if (initialized) return;
    // const initApi = initApiClient();

    initializeApp();
  }, [initialized, authorized, currentUser]);


  useEffect(() => {
    if (socket && jsonWebToken) {
      socket.emit("JOIN_ROOM", { jwt: localStorage.getItem("JWT_TOKEN") });

      socket.on("UPLOAD_STATUS", (data: any) => {
        console.log("UPLOAD_STATUS", data);
        setUploadStatus(data);
      });

      socket.on("reconnect", () => {
        if (localStorage.getItem("JWT_TOKEN")) {
          socket.emit("JOIN_ROOM", { jwt: localStorage.getItem("JWT_TOKEN") });
          console.log(`reconnected`);
        }
      });

      socket.on("connect", () => {
        if (localStorage.getItem("JWT_TOKEN")) {
          socket.emit("JOIN_ROOM", { jwt: localStorage.getItem("JWT_TOKEN") });
          console.log(`reconnected`);
        }
      });

      socket.on("connect_error", (err) => {
        console.log(`connect_error due to ${err.message}`);
      });
    }
  }, [socket, jsonWebToken]);

  // Chatbot Creation

  const initiateBotCreation = async (
    selectedUrls: {
      name: string;
      sourceType: string;
      sourceData: { [key: string]: any };
    }[],
    customization: {
      name: string;
      avatar: string;
      logo: string;
      color: string;
    },
    setLoadingModal: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    console.log("initiating creation");

    if (!socket) return false;
    let botCreated = await createBot([], customization, setLoadingModal, selectedUrls);
    return botCreated;
  };

  const createBot = async (
    sources: string[],
    customization: {
      name: string;
      avatar: string;
      logo: string;
      color: string;
    },
    setLoadingModal: React.Dispatch<React.SetStateAction<boolean>>,
    selectedUrls: {
      name: string;
      sourceType: string;
      sourceData: { [key: string]: any };
    }[]
  ) => {
    setLoading(true);
    console.log(selectedUrls);

    try {
      let createChatResponse = await createChatBot({
        sources: [],
        assistant_name: customization.name,
        widget_avatar: customization.avatar,
        widget_color: customization.color,
        widget_logo: customization.logo,
      });
      if (createChatResponse.result === "SUCCESS") {
        if (currentUser.onboarded === false) {
          await onboardUser();
          setCurrentUser((prev: CurrentUser) => ({
            ...prev,
            onboarded: true,
          }));
        }

        let localSources = selectedUrls.filter((source) => source.sourceType === "file-upload");
        let nonLocalSources = selectedUrls.filter((source) => source.sourceType !== "file-upload");
        let allSourcesArr = [];

        for (let src of localSources) {
          let fileUploadRes = await postMediaContent({
            file: src.sourceData.file,
          });
          console.log(fileUploadRes.fileURL);

          if (fileUploadRes?.content?.acknowledged) {
            let res = await createFileUploadSource({
              file: fileUploadRes.fileURL,
              chatbotID: createChatResponse.data.chatbotID,
              originalName: src.sourceData.file.name,
            });
            if (res.result === "SUCCESS") {
              allSourcesArr.push(res.data as string);
            }
          }
        }

        if (nonLocalSources.length > 0) {
          let createSourcesRes = await createSources({
            files: nonLocalSources,
            chatbotID: createChatResponse.data.chatbotID,
          });
          allSourcesArr = [...allSourcesArr, ...createSourcesRes.data];
        }

        socket.emit("UPLOAD_SOURCES", {
          sources: allSourcesArr,
        });
        setCurrentChatbot(createChatResponse.data);
        setUserChatbots((prev) => [...prev, createChatResponse.data]);
        localStorage.setItem("CURRENT_CHATBOT", createChatResponse.data.chatbotID);
        setLoadingModal(false);
        setBotReady(true);
      }
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const syncSource = async (sourceID: string) => {
    socket.emit("SYNC_SOURCE", {
      sourceID: sourceID,
    });
  };

  const AddSourcesToBot = async () => {
    if (!currentChatbot) throw new Error();
    try {
      let localSources = selectedSources.filter((source) => source.sourceType === "file-upload");
      let nonLocalSources = selectedSources.filter((source) => source.sourceType !== "file-upload");
      let allSourcesArr = [];
      let delSources = [];

      for (let src of localSources) {
        try {
          let fileUploadRes = await postMediaContent({
            file: src.sourceData.file,
          });
          console.log(fileUploadRes.fileURL);
          if (fileUploadRes?.content?.acknowledged) {
            let res = await createFileUploadSource({
              file: fileUploadRes.fileURL,
              chatbotID: currentChatbot.chatbotID,
              originalName: src.sourceData.file.name,
            });
            if (res.result === "SUCCESS") {
              allSourcesArr.push(res.data as string);
            }
          }
        } catch (err) {
          console.log(err);
          alert("Sorry! We had a problem uploading this file.");
        }
      }
      if (nonLocalSources.length > 0) {
        let createSourcesRes = await createSources({
          files: nonLocalSources,
          chatbotID: currentChatbot.chatbotID,
        });
        delSources = createSourcesRes.deleted_sources;
        allSourcesArr = [...allSourcesArr, ...createSourcesRes.data];
      }
      socket.emit("UPLOAD_SOURCES", {
        sources: allSourcesArr,
      });
      return {
        result: "SUCCESS",
        data: allSourcesArr,
        deleted_sources: delSources,
      };
    } catch (err: any) {
      throw new Error(err);
    }
  };

  // get the users chatbots
  useEffect(() => {
    if (initialized && authorized)
      getUserChatbots().then((res: { result: string; data: ChatbotType[] }) => {
        if (res.result === "SUCCESS") {
          setUserChatbots(res.data);

          if (localStorage.getItem("CURRENT_CHATBOT")) {
            let currentBot = localStorage.getItem("CURRENT_CHATBOT");
            let bot = res.data.find((chatbot) => chatbot.chatbotID === currentBot);
            if (bot) {
              setCurrentChatbot(bot);
            } else if (res.data.length > 0) {
              setCurrentChatbot(res.data[0]);
            }
          } else if (res.data.length > 0) {
            setCurrentChatbot(res.data[0]);
          }
        }
      });
  }, [initialized, authorized]);

  // set current chatbot
  return (
    <ClientCtx.Provider
      value={{
        initialized,
        initializeApp,
        setCurrentUser,
        currentUser,
        messages,
        setMessages,
        loading,
        setLoading,
        assistantName,
        isDemo,
        logoData,
        data,
        setData,
        companyName,
        setCompanyName,
        createBot,
        initiateBotCreation,
        googleDriveConnected,
        setGoogleDriveConnected,
        currentChatbot,
        setCurrentChatbot,
        userChatbots,
        isLoginOpen,
        setLoginOpen,
        isSignupOpen,
        setSignupOpen,
        authorized,
        setAuthorized,
        progressData,
        setJsonWebToken,
        selectedSources,
        setSelectedSources,
        AddSourcesToBot,
        creationModalOpen,
        setCreateOpen,
        uploadStatus,
        syncSource,
        isBotReady,
        setBotReady,
        sources,
        setSources,
        isFeedbackOpen,
        setFeedbackOpen,
        setForgotOpen,
        isDeleteModalOpen,
        setDeleteModalOpen,
        isForgotOpen,
        infoboxaiBETA,
        socket
      }}
    >
      {children}
    </ClientCtx.Provider>
  );
};

export const useClient = () => {
  return useContext(ClientCtx) as AuthContext;
};

export default ClientProvider;
