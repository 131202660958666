import React, { useState } from "react";
import { useFormik } from "formik";
import { signupUser, initJWT, getUserData } from "../../../actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleRight,
  faArrowLeftLong,
} from "@fortawesome/free-solid-svg-icons";
import { Checkbox } from "@mui/material";
import { LoginStyles } from "../LoginStyles";
import { useClient } from "../../../context/ClientContext";
import { useNavigate } from "react-router-dom";
import SplashStyles from "../../../pages/SplashPage/SplashStyles";
import { CurrentUser } from "../../../types/AuthTypes";

const validate = (values: any) => {
  const errors: any = {};
  if (!values.firstName) {
    errors.firstName = "This field is required";
  } else if (values.firstName.length > 15) {
    errors.firstName = "Must be 15 characters or less";
  }

  if (!values.lastName) {
    errors.lastName = "This field is required";
  } else if (values.lastName.length > 20) {
    errors.lastName = "Must be 20 characters or less";
  }

  if (!values.email) {
    errors.email = "This field is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (!values.password) {
    errors.password = "This field is required";
  } else if (values.password.length < 8) {
    errors.password = "Password is too short"
  } else if (values.password !== values.password2) {
    errors.password2 = "Passwords must match";
  } 
  if (!values.agreement) {
    errors.agreement = "This field is required";
  }
  return errors;
};

type Props = {
  open: boolean;
  setOpenEmailSignUp: React.Dispatch<React.SetStateAction<boolean>>;
  onLoginClick: () => void;
};

const SignUpWithEmail: React.FC<Props> = ({ open, setOpenEmailSignUp }) => {
  const navigate = useNavigate();
  const [waitlistError, setWaitlistError] = useState(``);
  const { setAuthorized, infoboxaiBETA, setJsonWebToken, setCurrentUser } =
    useClient();

  const urlParams = new URLSearchParams(window.location.search);
  const encodedEmail = urlParams.get("user_invite");
  const waitlistEmail = encodedEmail
    ? JSON.parse(window.atob(encodedEmail)).email
    : "";

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: waitlistEmail ? waitlistEmail : "",
      password: "",
      password2: "",
      agreement: false,
    },
    validate,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });
  if (!open) {
    return null;
  }

  const checkWaitlist = (email: string) =>
    infoboxaiBETA.getSubcriberByEmail(email);

  const handleSignUp = async (values: {
    email: string;
    password: string;
    firstname: string;
    lastname: string;
  }) => {
          signupUser(values).then((res: any) => {
            if (res.result === 'SUCCESS') {
              localStorage.setItem("JWT_TOKEN", res.JWT_TOKEN);
              localStorage.setItem("REFRESH_TOKEN", res.REFRESH_TOKEN);
              initJWT(localStorage.getItem('JWT_TOKEN') as string)
                      .then(() => {
                        setJsonWebToken(res.REFRESH_TOKEN);
                        getUserData().then(async (res: any) => {
                          const isOnboarded = res.content.onboarded;
                          await setCurrentUser(res.content as CurrentUser);
                          setAuthorized(true);
                          navigate(isOnboarded ? "/data" : "/create", {
                            replace: true,
                          });
                        });
                      })
                      .catch((err: any) => console.error(err));
            } else {
              if (res.data === 'user already exist')
              setWaitlistError('An account already exists for this email');
            }
            
          });
  };

  const handleLoginOpen = () => {
    navigate('/auth/login');
  };

  return (
    <>
      <SplashStyles.BackIcon>
        <FontAwesomeIcon
          style={{ cursor: "pointer" }}
          onClick={() => setOpenEmailSignUp(false)}
          icon={faArrowLeftLong}
          size="xl"
        />
      </SplashStyles.BackIcon>
      <div className="copy">
        <LoginStyles.Header>Sign up</LoginStyles.Header>
      </div>
      {waitlistError && (
        <LoginStyles.WaitlistError style={{ marginBottom: "30px" }}>
          {waitlistError}
        </LoginStyles.WaitlistError>
      )}
      <form
        onSubmit={formik.handleSubmit}
        style={{ width: "334px", maxWidth: "80%" }}
        noValidate
      >
        <LoginStyles.TextFieldWrapper>
          <LoginStyles.TextField
            autoComplete="off"
            placeholder="First Name"
            id="firstName"
            name="firstName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstName}
          />
          {formik.touched.firstName && formik.errors.firstName ? (
            <div className="errors">{formik.errors.firstName}</div>
          ) : null}
        </LoginStyles.TextFieldWrapper>
        <LoginStyles.TextFieldWrapper>
          <LoginStyles.TextField
            placeholder="Last Name"
            autoComplete="off"
            id="lastName"
            name="lastName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lastName}
          />
          {formik.touched.lastName && formik.errors.lastName && (
            <div className="errors">{formik.errors.lastName}</div>
          )}
        </LoginStyles.TextFieldWrapper>
        <LoginStyles.TextFieldWrapper>
          <LoginStyles.TextField
            placeholder="Email"
            id="email"
            name="email"
            type="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            disabled={!!waitlistEmail}
          />
          {formik.touched.email && formik.errors.email && (
            <div className="errors">{formik.errors.email as string}</div>
          )}
        </LoginStyles.TextFieldWrapper>
        <LoginStyles.TextFieldWrapper>
          <LoginStyles.TextField
            placeholder="Password"
            autoComplete="off"
            type="password"
            id="password"
            name="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
          />
          <LoginStyles.HelperText>
            Password must be at least 8 characters
          </LoginStyles.HelperText>
          {formik.touched.password && formik.errors.password && (
            <div className="errors">{formik.errors.password}</div>
          )}
        </LoginStyles.TextFieldWrapper>
        <LoginStyles.TextFieldWrapper>
          <LoginStyles.TextField
            autoComplete="off"
            placeholder="Confirm Password"
            type="password"
            id="password2"
            name="password2"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password2}
          />
          {formik.touched.password2 && formik.errors.password2 && (
            <div className="errors">{formik.errors.password2}</div>
          )}
        </LoginStyles.TextFieldWrapper>
        <label htmlFor="confirm-age" className="confirm-age">
          <Checkbox
            name="agreement"
            checked={formik.values.agreement}
            onChange={formik.handleChange}
          />
          I confirm that I am 18 years or older
        </label>
        {formik.touched.agreement && formik.errors.agreement && (
          <div className="errors">{formik.errors.agreement}</div>
        )}
      </form>
      <LoginStyles.Terms>
        By signing up, you are agreeing to Elevux's{" "}
        <LoginStyles.ColoredLink
          target="_blank"
          href="https://legal.infobox.ai/infoboxai-terms-and-conditions.html"
        >
          Terms
        </LoginStyles.ColoredLink>{" "}
        and{" "}
        <LoginStyles.ColoredLink
          target="_blank"
          href="https://legal.infobox.ai/infoboxai-privacy-policy.html"
        >
          Privacy Policy.
        </LoginStyles.ColoredLink>
      </LoginStyles.Terms>
      <LoginStyles.SubmitButton
        onClick={() => {
          if (
            formik.values.password === formik.values.password2 &&
            formik.values.agreement
          ) {
            handleSignUp({
              email: formik.values.email,
              password: formik.values.password,
              firstname: formik.values.firstName,
              lastname: formik.values.lastName,
            });
          }
        }}
        type="submit"
        disabled={!formik.isValid && formik.dirty}
        $isValid={formik.isValid}
        $dirty={formik.dirty}
        style={{
          marginBottom: '0px'
        }}
      >
        <div>Create Account</div>
        <FontAwesomeIcon icon={faArrowCircleRight} size="xl" />
      </LoginStyles.SubmitButton>
    </>
  );
};

export default SignUpWithEmail;
