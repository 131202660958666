import React, { useEffect, useState } from "react";
import { useClient } from "../../context/ClientContext";
import { decryptPasswordReset, updatePassword } from "../../actions";
import { useFormik } from "formik";
import { LoginStyles } from "../../components/Login/LoginStyles";
import SplashStyles from "../SplashPage/SplashStyles";
import InfoboxLogo from "../../assets/images/infoboxLogo.png";
import { faArrowCircleRight, faCircleCheck, faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

const PasswordReset = () => {
  const { ResetPassword } = LoginStyles;
  const navigate = useNavigate();
  const { initialized } = useClient();
  const urlParams = new URLSearchParams(window.location.search);
  const accessToken = urlParams.get("access");
  const [isDecrypting, setDecrypting] = useState(true);
  const [newPwCreated, setNewPwCreated] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [jwt, setJwt] = useState("");
  const [errorMessage, setErrorMessage] = useState('');
  const validate = (values: any) => {
    const errors: any = {};
    if (!values.password) {
      errors.password = "This field is required";
    } else if (values.password.length < 8) {
      errors.password = "Password is too short";
    } else if (values.password !== values.password2) {
      errors.password2 = "Passwords must match";
    }
    return errors;
  };

  const handleShowPassword = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (!accessToken || !initialized) return;
    decryptPasswordReset({ encoding: accessToken }).then((res: any) => {
      if (res.result === "SUCCESS") {
        console.log('jwt res', res)
        setJwt(res.JWT_TOKEN);
        setDecrypting(false);
      } else {
        console.log("Something went wrong");
      }
    });
  }, [accessToken, initialized]);

  const handleSubmit = () => {
    if (formik.isValid && formik.values.password.length >= 8 && !isDecrypting) {
      console.log('running', { password: formik.values.password, token: jwt})
      updatePassword({ password: formik.values.password, token: jwt }).then(
        (res: any) => {
          console.log('password update', res)
          if (res.result === "SUCCESS") {
            setNewPwCreated(true);
          } else if (res.result === "FAILED" && res.error === 'same password') {
            setErrorMessage("Please use a password different from your previous one")
          } else {
            console.log("Password reset error");
          }
        }
      );
    }
  };

  const backToLogin = () => {
    navigate('/auth/login')
  }

  const formik = useFormik({
    initialValues: { password: "", password2: "" },
    validate,
    onSubmit: handleSubmit,
  });

  

  return (
    <ResetPassword.Container>
      <div>
        <SplashStyles.HomeHeader>
          <SplashStyles.HomeLogo
            style={{margin: "auto"}}
            src={InfoboxLogo}
          />
        </SplashStyles.HomeHeader>
        <ResetPassword.Title>
          Your Knowledge Powered by{" "}
          <SplashStyles.Highlight>AI</SplashStyles.Highlight>
        </ResetPassword.Title>
      </div>
      {!newPwCreated && (
        <ResetPassword.Form>
          <ResetPassword.FormTitle>
            Create a new password
          </ResetPassword.FormTitle>
          <ResetPassword.FormSubtext>
            Password must be different from your previous one
          </ResetPassword.FormSubtext>
          
          <LoginStyles.TextFieldWrapper style={{ maxWidth: "332px" }}>
          <LoginStyles.TextField
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                InputProps={{
                  endAdornment: showPassword ? (
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      onClick={handleShowPassword}
                      icon={faEyeSlash}
                    />
                  ) : (
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      onClick={handleShowPassword}
                      icon={faEye}
                    />
                  ),
                }}
              />
            <LoginStyles.HelperText>
              Password must be at least 8 characters
            </LoginStyles.HelperText>
            {formik.touched.password && formik.errors.password && (
              <ResetPassword.Error>
                {formik.errors.password}
              </ResetPassword.Error>
            )}
          </LoginStyles.TextFieldWrapper>

          <LoginStyles.TextFieldWrapper style={{ maxWidth: "332px" }}>
          <LoginStyles.TextField
                placeholder="Password"
                type="password"
                id="password2"
                name="password2"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password2}
              />
            {formik.touched.password2 && formik.errors.password2 && (
              <ResetPassword.Error>
                {formik.errors.password2}
              </ResetPassword.Error>
            )}
          </LoginStyles.TextFieldWrapper>
          {errorMessage && <LoginStyles.ErrorMessage>{errorMessage}</LoginStyles.ErrorMessage>}
          <LoginStyles.SubmitButton
            onClick={(e) => {
              e.preventDefault();
              handleSubmit()}}
            $isValid={formik.isValid}
            $dirty={formik.dirty}
            disabled={!formik.isValid}
          >
            Reset Password
            <FontAwesomeIcon icon={faArrowCircleRight} size="xl" />
          </LoginStyles.SubmitButton>
        </ResetPassword.Form>
      )}
      {newPwCreated && (
        <SplashStyles.FlexColumn
          style={{ padding: '0 24px', margin: "auto", alignItems: "center" }}
        >
          <FontAwesomeIcon style={{marginBottom: '30px'}} icon={faCircleCheck} size="4x" color="#3fc952" />
          <ResetPassword.FormTitle style={{fontSize: '30px', fontWeight: '300'}}>
            Your password has been updated!
          </ResetPassword.FormTitle>
          <ResetPassword.FormSubtext>
            Use your new password to log into your account.
          </ResetPassword.FormSubtext>
          <LoginStyles.SubmitButton
            onClick={() => {backToLogin()}}
            $isValid={true}
            $dirty={true}
            style={{ justifyContent: "center", padding: "0" }}
          >
            Log in
          </LoginStyles.SubmitButton>
        </SplashStyles.FlexColumn>
      )}
      <SplashStyles.FlexRow $padding="20px 0">
      <SplashStyles.LegalFooter style={{ marginRight: "4vw" }}>
        &copy; {new Date().getFullYear()} All Rights Reserved. Elevux &reg;
      </SplashStyles.LegalFooter>
      </SplashStyles.FlexRow>
    </ResetPassword.Container>
  );
};

export default PasswordReset;
